<template>
  <div>
    <el-button type="primary" @click="add">添加模块</el-button>
    <el-table :data="tableData" stripe style="width: 100%;height: 400px;overflow: auto;">
      <el-table-column label="序号" width="180">
        <template slot-scope="scope">{{scope.$index+1}}</template>
      </el-table-column>
      <el-table-column prop="module_name" label="姓名" width="180"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" content="修改" placement="top-start">
            <a href="#" @click="edit(scope.row)" style="margin-left: 10px;">
              <i class="el-icon-edit"></i>
            </a>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="删除" placement="top-start">
            <el-button class="delbtn" style="border:none" @click="delecomfir(scope.row.mid)">
              <i class="el-icon-takeaway-box"></i>
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="添加BUG模块" :visible.sync="dialogFormVisible">
      <el-form :model="form">
        <el-form-item label="模块名称" :label-width="formLabelWidth">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="formsub">确 定</el-button>
      </div>
    </el-dialog>
    <div class="page">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage2"
        :page-sizes="[10, 30, 100, 200]"
        :page-size="pagesize"
        layout="sizes, prev, pager, next"
        :total="pagetotal"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      dialogFormVisible: false,
      form: {
        id: "",
        name: "",
      },
      formLabelWidth: "120px",
      pagetotal: 0, //总数据量
      currentPage2: 1,
      pagesize: 10,
    };
  },
  mounted() {
    this.getdata(this.pagesize, this.currentPage2);
  },
  methods: {
    formsub() {
      if (this.form.name == "") {
        this.$message.error("请填写模块名称哦。。不然不让你提交哦。。。。");
      } else {
        this.$axios
          .post("bug/set/add", {
            type: "module",
            id: this.form.id,
            name: JSON.parse(JSON.stringify(this.form.name)),
          })
          .then((res) => {
            if (res.code == 200) {
              if (res.isset == true) {
                this.$message.error(res.msg);
              } else {
                this.getdata();
                this.pagetotal = res.count;
                this.$message.success("添加成功");
                this.dialogFormVisible = false;
              }
            } else {
              this.$message.error("添加是吧..");
            }
          });
      }
    },
    delecomfir(id) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //todo 数据库操作
          let that = this;
          this.$axios
            .post("/bug/set/delete", {
              type: "module",
              id: JSON.parse(JSON.stringify(id)),
            })
            .then((res) => {
              if (res.code == 200) {
                if (res.use == 1) {
                  this.$message.error(res.msg);
                } else {
                  this.getdata(that.pagesize, that.currentPage2);
                  this.$message.success("删除成功!");
                }
              } else {
                this.$message.error(res.msg);
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    getdata() {
      let that = this;
      this.$axios
        .post("/bug/set/getdata", {
          type: "module",
          page: this.currentPage2,
          num: this.pagesize,
        })
        .then((res) => {
          if (res.code == 200) {
            that.tableData = res.data;
          }
        });
    },
    add() {
      this.formrestart();
      this.dialogFormVisible = true;
    },
    edit(data) {
      this.form.id = data.mid;
      this.form.name = data.module_name;
      this.dialogFormVisible = true;
    },
    formrestart() {
      this.form.id = 0;
      this.form.name = "";
    },
    handleSizeChange(val) {
      //todo 数据库查询
      this.pagesize = val;
      this.getdata(this.pagesize, this.currentPage2);
    },
    handleCurrentChange(val) {
      this.currentPage2 = val;
      this.getdata(this.pagesize, this.currentPage2);
    },
  },
};
</script>

<style scoped>
</style>
<template>
  <div>
    <el-row :gutter="20">
      <!-- bug模块 -->
      <el-col :span="10" :offset="1">
        <div class="grid-content bg-purple">
          <MODULE />
        </div>
      </el-col>
      <!-- 管理员 -->
      <el-col :span="10" :offset="1 ">
        <div class="grid-content bg-purple">
          <FIXMAN />
        </div>
      </el-col>
    </el-row>
  </div>
</template>


<script>
import MODULE from "@/components/Bug/addmodule";
import FIXMAN from "@/components/Bug/addfixman";
export default {
  components: {
    MODULE,
    FIXMAN,
  },
};
</script>

<style scoped>
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
</style>